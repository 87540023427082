<template>
  <div>
    <v-app id="inspire" style="height: 600px !important">
      <v-dialog v-model="dialog2" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Rol Seç</span>
          </v-card-title>
          <v-card-text>
            <v-container height="600px">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="roleidUpdate"
                    append-icon="search"
                    label="Rol Seç"
                    single-line
                    hide-details
                    :items="getworkmemberRole"
                    item-text="value"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="3 ml-auto">
                <button class="btn btn-secondary" @click="dialog2 = false">
                  İptal
                </button>
              </v-col>
              <v-col cols="3">
                <button class="btn btn-primary" @click="updateRole">
                  Güncelle
                </button>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- loading dialog -->
      <v-dialog v-model="loading" persistent :width="width">
        <v-card :color="color" :light="light" :dark="dark">
          <v-card-text>
            <v-card-title> {{ msg }}</v-card-title>
            <v-progress-linear
              :value="percentComplete"
              :indeterminate="indeterminate"
              :color="barColor"
              class="mb-0"
            >
            </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- loading dialog end -->
      <v-container>
        <v-row>
          <v-col md="12">
            <v-tabs grow v-model="tab">
              <v-tab centered href="#calismadetayi"> Çalışma Detayı </v-tab>

              <v-tab href="#calismadosyalari" :disabled="tabDisabled">
                Çalışma Dosyaları
              </v-tab>

              <v-tab href="#vizitler" :disabled="tabDisabled"> Vizitler </v-tab>
              <v-tab href="#calismaekibi" :disabled="tabDisabled">
                Çalışma Ekibi
              </v-tab>
            </v-tabs>

            <v-tabs-items :value="tab">
              <v-tab-item value="calismadetayi">
                <v-container>
                  <v-form id="calismadetayi" v-model="calismaDetayiValid">
                    <v-row>
                      <v-col lg="3">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.workShortName"
                                required
                                label="Çalışmanın Kısa İsmi (*) "
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                      </v-col>
                      <v-col lg="9">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.workName"
                                required
                                label="Çalışmanın Açık İsmi (*)"
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col lg="3" align-self="center">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="selected.createDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="getDateFormat(selected.createDate)"
                                    label="Başlangıç Tarihi (*)"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    filled
                                  >
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="selected.createDate"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                    class="pr-10"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.menu.save(selected.createDate)
                                    "
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.surveyId"
                                required
                                label="Survey ID (*)"
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.numberOfPatients"
                                required
                                label="Planlanan Tahmini Hasta Sayısı (*)"
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                           <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.approachingCount"
                                required
                                label="Yaklaşıyor Uyarısı"
                                filled
                                type="number"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                      </v-col>
                      <v-col lg="3" align-self="center">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="selected.completionDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="
                                      getDateFormat(selected.completionDate)
                                    "
                                    label="Tamamlanma Tarihi (*)"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    filled
                                  >
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="selected.completionDate"
                                  no-title
                                  scrollable
                                  :min="selected.createDate"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="menu2 = false"
                                    class="pr-10"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.menu2.save(selected.completionDate)
                                    "
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.protocolNumber"
                                required
                                label="Protokol Numarası (*)"
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.visitInterval"
                                required
                                label="Planlanan Vizit Aralığı(Gün) (*)"
                                filled
                                :rules="rules.name"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                           <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.laggingCount"
                                required
                                label="Gecikiyor (Sarı Uyarı)"
                                filled
                                type="number"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                      </v-col>
                      <v-col lg="3" align-self="center">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                v-model="selected.researchProduct"
                                label=" Araştırma Ürünü"
                                filled
                                :items="researchProductItems"
                                item-text="text"
                                item-value="value"
                                @change="changeResearchSubType"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                v-model="selected.researchType"
                                label=" Araştırmanın Tipi"
                                filled
                                :items="researchTypeItems"
                                item-text="text"
                                item-value="value"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                :items="supportersitem"
                                item-text="organizationName"
                                item-value="organizationName"
                                v-model="selected.support"
                                label=" Destekleyici"
                                filled
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                            <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.delayedCount"
                                required
                                label="Gecikti (Kırmızı Uyarı)"
                                filled
                                type="number"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                      </v-col>
                      <v-col lg="3" align-self="center">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                v-model="selected.researchSubType"
                                label=" Araştırma Alt Tipi"
                                filled
                                :items="researchSubTypeItems"
                                item-text="text"
                                item-value="value"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                v-model="selected.status"
                                label=" Durum/Statü"
                                filled
                                :items="statusItems"
                                item-text="text"
                                item-value="value"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-select
                                :items="responsibleCroitems"
                                v-model="selected.responsibleCro"
                                item-text="organizationName"
                                item-value="id"
                                label="Sorumlu CRO"
                                filled
                              ></v-select>
                            </v-col>
                          </v-row>
                        </div>
                         <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="selected.notHappenedCount"
                                required
                                label="Gerçekleşmedi Uyarısı"
                                filled
                                type="number"
                              ></v-text-field> </v-col
                          ></v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <div class="form-group">
                      <v-row>
                        <v-col lg="3">
                          <v-checkbox
                            v-model="selected.isSignatureRequired"
                            :label="`İmza Zorunluluğu`"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="3">
                          <v-switch
                          v-model="selected.isActive"
                          flat
                          :label="`Hasta Alımına ${selected.isActive==true?'Açık':'Kapalı'}`"
                    ></v-switch>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row>
                      <v-col lg="2 ml-auto" md="3 ml-auto" sm="4 ml-auto">
                        <button
                          id="btnSave"
                          type="button"
                          class="btn btn-save"
                          @click="createWorkdetails"
                          v-if="!updateButtonShow"
                          :disabled="!calismaDetayiValid"
                        >
                          Kaydet
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="updateWorkDetails"
                          v-if="updateButtonShow"
                          :disabled="!calismaDetayiValid"
                        >
                          Güncelle
                        </button>
                      </v-col>
                      <v-col lg="1 " md="3 " sm="4 ">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="goTabWorkFile"
                          v-if="forwardButtonShow"
                        >
                          İleri
                        </button>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-tab-item>
              <v-tab-item value="calismadosyalari">
                <v-container>
                  <v-form v-model="isFormValid">
                    <v-row>
                      <v-col xl="3" lg="6" md="6">
                        <v-text-field
                          v-model="fileNameSend"
                          required
                          label="Eklemek İstediğiniz Dosya İsmi (*)"
                          filled
                          :rules="rules.name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-form id="calismadosyalari" v-model="calismaDosyalariValid">
                    <v-row>
                      <v-col md="3" lg="6" xl="6">
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-file-input
                                ref="fileupload"
                                filled
                                :rules="[
                                  (value) =>
                                    !value ||
                                    value.size < 10000000 ||
                                    'Dosya en fazla 10 MB olmalı.',
                                ]"
                                :placeholder="'Dosya Seçiniz (*)'"
                                prepend-icon="mdi-file"
                                :label="'Dosya Seçiniz (*)'"
                                @change="changeFile"
                                show-size
                              ></v-file-input>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col cols="1 mr-auto" style="padding: 0px">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="addFileToTable"
                          :disabled="!uploadFileButton"
                        >
                          Yükle
                        </button>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-data-table
                          :headers="fileNameTable"
                          :items="fileTableItems"
                          :footer-props="{
                            'items-per-page-options': [20, 30, 40, -1],
                          }"
                          :items-per-page="20"
                        >
                          <template v-slot:[`item.delete`]="{ item }">
                            <v-icon
                              class="text-danger pr-3"
                              @click="deleteItemFromFileTable(item)"
                            >
                              fas fa-minus-circle
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-row>
                        <!-- <v-col lg="1 ml-auto" md="3 ml-auto" sm="4 ml-auto">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            @click="turnBackWorkDetailTab"
                          >
                            Geri
                          </button>
                        </v-col> -->
                        <v-col lg="1 ml-auto " md="3 ml-auto " sm="4 ml-auto ">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="GoTabVisits"
                          >
                            İleri
                          </button>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-form>
                </v-container>
              </v-tab-item>
              <v-tab-item value="vizitler">
                <v-container>
                  <v-form id="calismavizitler" v-model="calismaVizitlerValid">
                    <v-row>
                      <v-col md="12">
                        <div
                          class="form-group"
                          v-for="(item, index) in visitFile"
                          :key="index"
                        >
                          <v-row>
                            <v-col md="3">
                              <v-text-field
                                filled
                                required
                                v-model="item.visitName"
                                :name="`visitFile[${index}][visitName]`"
                                label="Vizit Adı"
                                :rules="rules.name"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col md="1">
                              <v-text-field
                                filled
                                required
                                v-model="item.pageId"
                                :name="`visitFile[${index}][pageId]`"
                                label="Page İd"
                                :rules="rules.name"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col md="2">
                              <v-menu
                                :ref="'element' + item.id"
                                v-model="item.menu3"
                                :close-on-content-click="false"
                                :return-value.sync="item.plannedDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    required
                                    :name="`visitFile[${index}][plannedDate]`"
                                    :value="getDateFormat(item.plannedDate)"
                                    label="Planlanan Tamamlanma Tarihi"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    filled
                                    :rules="rules.name"
                                  >
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="item.plannedDate"
                                  no-title
                                  scrollable
                                  :rules="rules.name"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="item.menu3 = false"
                                    class="pr-10"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="abc('element' + item.id, item)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col md="1" style="padding: 0px">
                              <button
                                type="button"
                                class="btn btn-primary"
                                @click="deleteVisit(index)"
                              >
                                Sil
                              </button></v-col
                            >
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col md="2">
                        <div class="form-group">
                          <v-text-field
                            required
                            label="Eklemek istediğiniz vizit sayısı (*)"
                            filled
                            v-model="count"
                            :rules="rules.name"
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col md="1" style="padding: 0px">
                        <div class="form-group">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="createVisitDetails()"
                          >
                            Ekle
                          </button>
                        </div>
                      </v-col>

                      <v-col lg="1 m-auto" md="3 ml-auto" sm="4 ml-auto">
                        <!-- <button
                          type="button"
                          class="btn btn-primary"
                          @click="addVisit"
                        >
                          Kaydet
                        </button> -->
                      </v-col>
                      <v-col lg="2 " md="3 " sm="4 ">
                        <!-- <button
                          type="button"
                          class="btn btn-secondary"
                          @click="turnBackTabWorkFile"
                        >
                          Geri
                        </button> -->
                        <button
                        id="btnaddVisit"
                          type="button"
                          class="btn btn-primary"
                          @click="addVisit"
                        >
                          Kaydet
                        </button>
                      </v-col>
                      <v-col lg="1 " md="3 " sm="4 ">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="goTabWorkMember"
                        >
                          İleri
                        </button>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-tab-item>
              <v-tab-item value="calismaekibi">
                <v-container>
                  <v-form id="calismaekibi" v-model="calismaEkibValid">
                    <v-row>
                      <v-col md="4">
                        <v-select
                          :rules="rules.name"
                          v-model="search"
                          label="Kullanıcı Seç (*)"
                          single-line
                          hide-details
                          :items="getworkmemberList"
                          :item-text="(item) => `${item.name}  ${item.surname}`"
                          item-value="id"
                        ></v-select>
                      </v-col>
                      <v-col md="4">
                        <v-select
                          :rules="rules.name"
                          v-model="roleid"
                          label="Rol Seç (*)"
                          single-line
                          hide-details
                          :items="getworkmemberRole"
                          item-text="value"
                          item-value="id"
                        ></v-select>
                      </v-col>
                      <v-col md="2">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="addWorkMember"
                        >
                          Kullanıcı Ata
                        </button>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="12">
                        <v-data-table
                          :headers="headers"
                          :items="desserts2"
                          :footer-props="{
                            'items-per-page-options': [20, 30, 40, -1],
                          }"
                          :items-per-page="20"
                        >
                          <template v-slot:[`item.fullName`]="{ item }">
                            <div>
                              {{ item.user.name + " " + item.user.surname }}
                            </div>
                          </template>
                          <template v-slot:[`item.edit`]="{ item }">
                            <v-icon
                              class="fa-pencil-blue"
                              @click="getRoleUpdateInfo(item)"
                            >
                              fas fa-pencil-alt
                            </v-icon>
                          </template>
                          <template v-slot:[`item.delete`]="{ item }">
                            <v-icon
                              class="text-danger"
                              @click="deleteMember(item)"
                            >
                              fas fa-minus-circle
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="1 ml-auto" md="3 ml-auto" sm="4 ml-auto">
                        <!-- <button
                          type="button"
                          class="btn btn-secondary"
                          @click="turnBackWorkDetailTab"
                        >
                          Geri
                        </button> -->
                      </v-col>
                      <v-col lg="3 " md="3 " sm="4 ">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="goWorkList"
                        >
                          Çalışma Listesi
                        </button>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
.v-input--selection-controls .v-input__slot > .v-label {
  margin: 0px !important;
  font-size: 1rem !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
//import Button from './vue-bootstrap/Button.vue';
export default {
  //components: { Button },
  name: "works",
  data() {
    return {
      fileNameSend: "",
      isFormValid: false,
      tabDisabled: true,
      forwardButtonShow: false,
      dialog2: false,
      roleidUpdate: "",
      updateButtonShow: false,
      calismaDetayiValid: true,
      calismaDosyalariValid: true,
      calismaVizitlerValid: true,
      calismaEkibValid: true,
      roleid: "",
      rules: {
        name: [(val) => !!val || "Bu alan doldurulmalı"],
      },
      array: [],
      workmemberboolean: true,
      centerid: null,
      calismaid: "",
      menu: false,
      pageid: "",
      menu2: false,
      menu3: false,
      //dialog: false,
      //date: new Date().toISOString().substr(0, 10),
      //date2: new Date().toISOString().substr(0, 10),
      search: "",
      desserts2: [],
      headers: [
        {
          text: "Ünvan",
          align: "left",
          value: "user.title",
          width: "120px",
        },
        //{ text: "Kullanıcı", value: "user.username" },
        { text: "Ad Soyad", value: "fullName", width: "150px" },
        //{ text: "Soyad", value: "user.surname", width: "100px" },
        { text: "Merkez/Org", value: "user.center.centerName", width: "150px" },
        { text: "Bölüm", value: "user.specialtyDepartment", width: "100px" },
        //{ text: "E-posta", value: "user.email" },
        //{ text: "Telefon", value: "user.phoneNumber" },
        { text: "Ülke", value: "user.country", width: "100px" },
        { text: "Şehir", value: "user.city", width: "100px" },
        { text: "Rol", value: "role", width: "100px" },
        { text: "Düzenle", value: "edit", sortable: false, width: "100px" },
        { text: "Sil", value: "delete", sortable: false, width: "100px" },
      ],
      fileTableItems: [],
      fileNameTable: [
        {
          text: "Dosya Adı",
          align: "left",
          sortable: false,
          value: "fileName",
        },
        { text: "Sil", align: "right", value: "delete", width: "20%" },
      ],
      e1: 1,
      responsibleCroitems: [],
      supportersitem: [],
      selected: {
        workName: "",
        isActive:false,
        workShortName: "",
        responsibleCro: "",
        protocolNumber: "",
        surveyId: "",
        numberOfPatients: "",
        visitInterval: "",
        createDate: new Date().toISOString().substr(0, 10),
        completionDate: new Date().toISOString().substr(0, 10),
        researchType: "",
        researchProduct: "",
        researchSubType: "",
        status: "",
        support: "",
        isSignatureRequired: false,
        approachingCount:"",
        laggingCount:"",
        delayedCount:"",
        notHappenedCount:""
      },
      formData: undefined,
      tempFile: null,
      visitFile: [],
      count: 0,
      statusItems: [
        { text: "Hasta Alımı Başlamadı", value: "Hasta Alımı Başlamadı" },
        { text: "Hasta Alımı Devam Ediyor", value: "Hasta Alımı Devam Ediyor" },
        {
          text: "Hastalar Kayıt Olmaya Davet Edildi",
          value: "Hastalar Kayıt Olmaya Davet Edildi",
        },
        { text: "Çalışma Devam Ediyor", value: "Çalışma Devam Ediyor" },
        { text: "Çalışma Tamamlandı", value: "Çalışma Tamamlandı" },
        { text: "Çalışma İptal Edildi", value: "Çalışma İptal Edildi" },
        {
          text: "Çalışma Geçici Olarak Durduruldu",
          value: "Çalışma Geçici Olarak Durduruldu",
        },
        {
          text: "Çalışma Tamamlanmadan Sonlandırıldı",
          value: "Çalışma Tamamlanmadan Sonlandırıldı",
        },
        { text: "Çalışma Geri Çekildi", value: "Çalışma Geri Çekildi" },
      ],
      researchTypeItems: [
        { text: "Girişimsel", value: "Girişimsel" },
        { text: "Gözlemsel", value: "Gözlemsel" },
        {
          text: "Gözlemsel(Kayıt Çalışması)",
          value: "Gözlemsel(Kayıt Çalışması)",
        },
        { text: "Genişletilmiş Erişim", value: "Genişletilmiş Erişim" },
      ],
      researchProductItems: [
        { text: "İlaç", value: "İlaç" },
        { text: "Tıbbi Cihaz", value: "Tıbbi Cihaz" },
        { text: "Aşı", value: "Aşı" },
        { text: "İlaç Dışı", value: "İlaç Dışı" },
      ],
      researchSubTypeItems: [],
      getworkmemberList: [],
      getworkmemberRole: [],
      temp: [],
      roleList: [],
      uploadFileButton: true,
      bodyTemp: {},
      visitFileTemp: null,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "Dosya Yükleniyor...",
    },
    width: {
      type: Number,
      default: 300,
    },
    color: {
      type: String,
      default: "grey",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    barColor: {
      type: String,
      default: "white",
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
    percentComplete: {
      type: Number,
      default: 100,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Çalışma Ekle", route: "/works" },
    ]);

    this.getUserCro();
    this.getSupporters();
    this.getworkmemberList2();
    this.getWorkMemberRoleList();
  },
  methods: {
    deleteMember(item) {
      //console.log("item silnidi", item.id);
      ApiService.delete("workmember?id=" + item.id)
        .then(() => {
          //console.log("Data silindi", data);
          this.getUserWorkDetails();
          Swal.fire({
            icon: "success",
            title: "Kullanıcı Silindi",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getDateFormat(item) {
      let date = item ? item.split("T")[0] : "-";
      // console.log("date",date);
      let date2 = new Date(date).toLocaleString("tr");
      //console.log("date",date2);
      let date3 = date2 ? date2.split(" ")[0] : "-";

      return date3;
    },
    getRoleUpdateInfo(item) {
      this.dialog2 = true;

      this.bodyTemp = {
        role: this.roleidUpdate,
        workId: item.workId,
        id: item.id,
      };
      //console.log("bodytemp", this.bodyTemp);
    },
    updateRole() {
      let body = {
        roleId: this.roleidUpdate,
        workId: this.bodyTemp.workId,
        id: this.bodyTemp.id,
      };

      ApiService.put("workmember", body)
        .then(() => {
          //console.log("Role güncellendi", data);
          this.dialog2 = false;
          Swal.fire({
            icon: "success",
            title: "Rol Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.getUserWorkDetails();
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          //console.log("error", err);
        });
    },
    updateWorkDetails() {
      let body = this.selected;
      //console.log("gönderilen data", body);
      ApiService.put("work", body)
        .then(() => {
          //console.log("Calisma güncellendi", data);
          this.getUserWorkDetails();
          Swal.fire({
            icon: "success",
            title: "Çalışma  Güncellendi",
            showConfirmButton: false,
            timer: 1200,
          });
          //this.$router.push({ name: "workslist" });
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          //console.log("error", err);
        });
    },
    //allowedDates: val => Date.parse(val) > Date.now() - 8.64e7,
    getUserWorkDetails() {
      ApiService.get("work", this.calismaid)
        .then(({ data }) => {
          //console.log("Çalışmalar geldi", data.result);
          this.selected = data.result;
          this.selected.isActive=data.isActive;
          this.selected.responsibleCro = data.addResult.croId;
          this.selected.createDate = data.result.createDate;
          this.selected.completionDate = data.result.completionDate;
          this.fileTableItems = data.result.files;
          this.desserts2 = data.result.workMembers;
          this.visitFile = data.result.visits;
          this.visitFileTemp = data.result.visits.length;
        })
        .catch(() => {
          //console.log("error", err);
        });
    },
    createWorkdetails() {
      document.getElementById('btnSave').disabled = true;
      let body = this.selected;
       console.log("body yeni : ",body);
      ApiService.post("/work", body)
        .then(({ data }) => {
          //console.log("Calisma olstu", data.result);
          this.calismaid = data.result.id;
          //this.uploadfile();
          this.updateButtonShow = true;
          this.forwardButtonShow = true;
          this.tabDisabled = false;
          this.getUserWorkDetails();
          this.getworkmemberList2();
          Swal.fire({
            icon: "success",
            title: "Çalışma  Oluşturuldu.",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          document.getElementById('btnSave').disabled = false;
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          //console.log("error", err);
        });

      // this.tab = "calismadosyalari";
    },
    addFileToTable() {
      //this.fileTableItems.push(this.tempFile);
      //console.log("tempfile", this.tempFile);
      if (this.tempFile != null) {
        if (this.tempFile.File.size == undefined) {
          return Swal.fire({
            title: "",
            text: "Lütfen bir dosya seçiniz.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        } else {
          //this.fileTableItems.push(this.tempFile);
          this.uploadfile();
        }
      } else {
        return Swal.fire({
          title: "",
          text: "Lütfen bir dosya seçiniz.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
    },
    deleteItemFromFileTable(item) {
      ApiService.delete("/file" + "?id=" + item.id)
        .then(() => {
          //console.log("Dosya silindi", data);
          // for (var i = 0; i < this.fileTableItems.length; i++) {
          //   if (this.fileTableItems[i].id === item.id) {
          //     this.fileTableItems.splice(i, 1);
          //   }
          // }
          this.getUserWorkDetails();
          Swal.fire({
            icon: "success",
            title: "Dosya  Silindi",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeFile(file) {
      if (file.size > 10000000) {
        //alert("Lütfen 10 MB dan az dosya yükleyiniz.");
        this.uploadFileButton = false;
        this.$refs.fileupload.value = null;
      } else {
        //formData.append("WorkId", this.calismaid);
        this.tempFile = null;
        this.uploadFileButton = true;
        this.tempFile = { File: file, FileName: this.fileNameSend };
      }
    },
    uploadfile() {
      // formData.append("File", this.tempFile.File);
      // formData.append("FileName", this.tempFile.FileName);
      // formData.append("WorkId", this.calismaid);

      let formData = new FormData();
      formData.append("File", this.tempFile.File);
      formData.append("FileName", this.fileNameSend);
      formData.append("WorkId", this.calismaid);
      this.msg = "Dosya Yükleniyor...";
      this.loading = true;
      ApiService.post("/file", formData)
        .then(({ data }) => {
          //console.log("dosya olstu", data.result);
          // this.array.push(data.result);
          this.fileTableItems.push(data.result);
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Dosya Eklendi",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    deleteFile(item) {
      ApiService.delete("/file" + "?id=" + item.id)
        .then(() => {
          for (var i = 0; i < this.array.length; i++) {
            if (this.array[i].id === item.id) {
              this.array.splice(i, 1);
            }
          }
        })
        .catch((err) => {
          //console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    createVisitDetails() {
      // if (this.visitFile.length != 0) {
      //   this.visitFile = [];
      // }
      console.log("count=>",this.count);
      if (this.count == 0) {
        Swal.fire({
          title: "",
          text: "Lütfen Eklemek İstediğiniz Vizit Sayısını Giriniz.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
      var toplam = parseInt(this.count) + parseInt(this.visitFileTemp);
      var indexStart = this.visitFileTemp + 1;
      for (let index = indexStart; index <= toplam; index++) {
        let obje = {
          visitName: "",
          pageId: "",
          plannedDate: new Date().toISOString().substr(0, 10),
          menu3: false,
          dateRef: null,
          workId: this.workID,
          id: index,
        };
        this.visitFile.push(obje);
      }
    },
    abc(ref, item) {
      //let b = "element" + item.id;

      this.$refs[ref][0].save(item.plannedDate);
    },
    changeResearchSubType() {
      this.researchSubTypeItems = [];

      if (this.selected.researchProduct == "Tıbbi Cihaz") {
        this.researchSubTypeItems.push(
          {
            text: "PILOT Klinik Araştırrma(CE'siz)",
            value: "PILOT Klinik Araştırrma(CE'siz)",
          },
          {
            text: "PIVOTAL Klinik Araştırrma(CE'siz)",
            value: "PIVOTAL Klinik Araştırrma(CE'siz)",
          },
          {
            text: "Klinik Araştırrma(CE'li)",
            value: "Klinik Araştırrma(CE'li)",
          }
        );
      } else {
        this.researchSubTypeItems.push(
          { text: "Faz 0", value: "Faz 0" },
          { text: "Faz 1", value: "Faz 1" },
          { text: "Faz 2", value: "Faz 2" },
          { text: "Faz 3", value: "Faz 3" },
          { text: "Faz 4", value: "Faz 4" },
          { text: "BY/BE Çalışmaları", value: "BY/BE Çalışmaları" }
        );
      }
    },
    getUserCro() {
      ApiService.get("usercro")
        .then(({ data }) => {
          // this.responsibleCroitems.push({
          //   organizationName: data.result.organizationName,
          //   id: data.result.id,
          // });
          this.responsibleCroitems = data.result;
        })
        .catch(() => {
          //console.log("error", err);
        });
    },
    getSupporters() {
      ApiService.get("supporters")
        .then(({ data }) => {
          this.supportersitem = data.result;
        })
        .catch(() => {
          //console.log("error", err);
        });
    },
    addVisit() {
      document.getElementById('btnaddVisit').disabled = true;
      this.msg = "Vizit Ekleniyor...";
      this.loading = true;
      let sendArray = [];
      //let sendArray = [];
      for (let index = 0; index < this.visitFile.length; index++) {
        if (
          this.visitFile[index].id == null ||
          this.visitFile[index].menu3 != null
        ) {
          let body = {
            visitName: this.visitFile[index].visitName,
            pageId: this.visitFile[index].pageId,
            plannedDate: this.visitFile[index].plannedDate,
            workId: this.calismaid,
          };
          sendArray.push(body);
        }
      }
      //console.log("send array giden", sendArray);
      ApiService.post("visits", sendArray)
        .then(() => {
          //console.log("visit olstu", data);
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Vizit Eklendi",
            showConfirmButton: false,
            timer: 1200,
          });
          this.getUserWorkDetails();
        })
        .catch(() => {
          document.getElementById('btnaddVisit').disabled = false;
        });
    },
    deleteVisit(index) {
      if (
        this.visitFile[index].visitName == "" &&
        this.visitFile[index].pageId == ""
      ) {
        this.visitFile.splice(index, 1);
      } else if (
        this.visitFile[index].visitName != "" &&
        this.visitFile[index].pageId == ""
      ) {
        this.visitFile.splice(index, 1);
      } else if (
        this.visitFile[index].visitName == "" &&
        this.visitFile[index].pageId != ""
      ) {
        this.visitFile.splice(index, 1);
      } else {
        if (index + 1 > this.visitFileTemp) {
          this.visitFile.splice(index, 1);
        } else {
          ApiService.delete("visit?id=" + this.visitFile[index].id)
            .then(() => {
              //console.log("Silindi", data);
              // for (var i = 0; i < this.visitFile.length; i++) {
              //   this.visitFile.splice(index, 1);
              // }
              Swal.fire({
                icon: "success",
                title: "Vizit  Silindi",
                showConfirmButton: false,
                timer: 1200,
              });
              this.getUserWorkDetails();
            })
            .catch((err) => {
              Swal.fire({
                title: "",
                text: err.response.data.message,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            });
        }
      }
    },
    GoTabVisits() {
      this.tab = "vizitler";
    },
    turnBackWorkDetailTab() {
      this.tab = "calismadetaylari";
    },
    goTabWorkMember() {
      this.tab = "calismaekibi";
    },
    turnBackTabWorkFile() {
      this.tab = "calismadosyalari";
    },
    goTabWorkFile() {
      this.tab = "calismadosyalari";
    },
    getworkmemberList2() {
      ApiService.get(
        "/users" + "?croDetail=" + true + "&workId=" + this.calismaid
      )
        .then(({ data }) => {
          this.getworkmemberList = data.result;
          //this.desserts2 =this.getworkmemberList;
        })
        .catch(() => {
          //console.log("error", err);
        });
    },
    getWorkMemberRoleList() {
      ApiService.get("workmemberroles")
        .then(({ data }) => {
          this.getworkmemberRole = data.result;
          //this.desserts2 =this.getworkmemberList;
        })
        .catch(() => {
          //console.log("error", err);
        });
    },
    addWorkMember() {
      let body = {
        workid: this.calismaid,
        userid: this.search,
        roleId: this.roleid,
      };
      if (this.search == "" && this.roleid != "") {
        return Swal.fire({
          title: "",
          text: "Lütfen Kullanıcı  Seçiniz",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else if (this.search != "" && this.roleid == undefined) {
        return Swal.fire({
          title: "",
          text: "Lütfen Kullanıcının Rolünü  Seçiniz",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else if (this.search == "" && this.roleid == "") {
        return Swal.fire({
          title: "",
          text: "Lütfen Kullanıcı ve  Rol  Seçiniz",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } else {
        ApiService.post("/workmember", body)
          .then(({ data }) => {
            //console.log("Calisma ekibi ekle dönen data", data);
            this.desserts2.push(data.result);
            // console.log("dessert2", this.desserts2);
            Swal.fire({
              icon: "success",
              title: "Çalışmaya Kullanıcı Eklendi",
              showConfirmButton: false,
              timer: 1200,
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "",
              text: err.response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            //console.log("error", err);
          });
      }
    },
    addWorkMemberTable() {
      if (this.search == "" && this.roleid == "") {
        return Swal.fire({
          title: "",
          text: "lütfen Kullanıcı Adı ve Rol Seçiniz.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }

      ApiService.get("/user", this.search)
        .then(({ data }) => {
          data.result.role = this.roleid;
          this.desserts2.push(data.result);
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          //console.log("error", err);
        });
    },
    goWorkList() {
      this.$router.push({ name: "workslist" });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        //console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
